import thumb1 from '../../src/images/data/tv_1_thumb.jpg';
import thumb2 from '../../src/images/data/tv_2_thumb.jpg';
import thumb3 from '../../src/images/data/tv_3_thumb.jpg';
import thumb4 from '../../src/images/data/tv_4_thumb.jpg';

import imgTV from '../../src/images/data/Sky_Glass_Retail_App_Assets_IT_pg8_Desktop.jpg';
import imgBB from '../../src/images/data/Sky_Q_Retail_App_Assets_IT_pg9_Desktop_thumb.png';
import imgMB from '../../src/images/data/Packages_Retail_App_Assets_IT_pg10_Desktop.png';
import imgST from '../../src/images/data/category_hero_DKY_STREAM_1920x1080px.jpg';

import logo from '../../src/assets/logo.jpg';

const tv = {
    data : {
        wp: {
            home: {
                dataHeader : {
                    home:true, 
                    logo: logo,
                    buttons : [
                        {
                            links : '/tv/glass',
                            label : 'Discover Sky Glass'
                        },
                        {
                            links : '/tv/glass/tech-specs',
                            label : 'Tech Talk'
                        },
                        {
                            links : '/tv/glass/configurator',
                            label : 'Choose your tv'
                        },
                    ]
                },
                title : 'Select a category',
                options : [
                    {
                        id: 'tv-glass',
                        title : 'Sky Glass',
                        thumb : thumb1,
                        img : imgTV,
                        link: '/tv/glass'
                    },
                    {
                        id: 'tv-stream',
                        title : 'Sky Stream',
                        thumb : thumb2,
                        img : imgST,
                        link: '/tv/stream'
                    },
                    {
                        id: 'tv-q',
                        title : 'Sky Q',
                        thumb : thumb3,
                        img : imgBB,
                        link: '/tv/q'
                    },
                    {
                        id: 'tv-packages',
                        title : 'Packages',
                        thumb : thumb4,
                        img : imgMB,
                        link: '/tv/packages'
                    },
                ]
            }
        },

    }
}

export default tv;